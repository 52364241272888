export default {
    all: function(){
        for (let f in this){
            if (typeof this[f] == "function" && this[f] !== this['all']){
                this[f]();
            }
        }
    },
    stringIncludes: function(){
        if (!String.prototype.includes) {
            String.prototype.includes = function() {
                'use strict';
                return String.prototype.indexOf.apply(this, arguments) !== -1;
            };
        }
    },
    stringRepeat: function() {
        if (!String.prototype.repeat) {
            String.prototype.repeat = function(count) {
                'use strict';
                if (this == null) throw new TypeError('can\'t convert ' + this + ' to object');
                var str = '' + this;
                count = +count;
                if (count != count) count = 0;
                if (count < 0) throw new RangeError('repeat count must be non-negative');
                if (count == Infinity) throw new RangeError('repeat count must be less than infinity');
                count = Math.floor(count);
                if (str.length == 0 || count == 0) return '';
                if (str.length * count >= 1 << 28) throw new RangeError('repeat count must not overflow maximum string size');
                var maxCount = str.length * count;
                count = Math.floor(Math.log(count) / Math.log(2));
                while (count) {
                    str += str;
                    count--;
                }
                str += str.substring(0, maxCount - str.length);
                return str;
            }
        }
    },
    arrayFind: function(){
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    if (this == null) { throw new TypeError('"this" is null or not defined');}
                    var o = Object(this);
                    var len = o.length >>> 0;
                    if (typeof predicate !== 'function') {throw new TypeError('predicate must be a function');}
                    var thisArg = arguments[1];
                    var k = 0;
                    while (k < len) {
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) { return kValue; }
                        k++;
                    }
                    return undefined;
                }
            });
        }
    },
    arrayIncludes: function(){
        if (!Array.prototype.includes) {
            Object.defineProperty(Array.prototype, 'includes', {
                value: function(searchElement, fromIndex) {
                    if (this == null) { throw new TypeError('"this" is null or not defined'); }
                    var o = Object(this);
                    var len = o.length >>> 0;
                    if (len === 0) { return false; }
                    var n = fromIndex | 0;
                    var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
                    function sameValueZero(x, y) {
                        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
                    }
                    while (k < len) {
                        if (sameValueZero(o[k], searchElement)) { return true; }
                        k++;
                    }
                    return false;
                }
            });
        }
    },
    arrayFindIndex: function(){
        if (!Array.prototype.findIndex) {
            Object.defineProperty(Array.prototype, 'findIndex', {
                value: function(predicate) {
                    if (this == null) throw new TypeError('"this" is null or not defined');
                    var o = Object(this);
                    var len = o.length >>> 0;
                    if (typeof predicate !== 'function') throw new TypeError('predicate must be a function');
                    var thisArg = arguments[1];
                    var k = 0;
                    while (k < len) {
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) return k;
                        k++;
                    }
                    return -1;
                }
            });
        }
    },
    objectAssign: function() {
        if (typeof Object.assign != 'function') {
            Object.defineProperty(Object, "assign", {
              value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                  throw new TypeError('Cannot convert undefined or null to object');
                }
                var to = Object(target);
                for (var index = 1; index < arguments.length; index++) {
                  var nextSource = arguments[index];
                  if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                      // Avoid bugs when hasOwnProperty is shadowed
                      if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                      }
                    }
                  }
                }
                return to;
              },
              writable: true,
              configurable: true
            });
          }
    }
}